import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";
import { constructQueryString } from "../../utils/CommonFunction";

const DeliveryListAction = createAsyncThunk(
    'delivery/list', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`/delivery-partners?${queryString}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const DeliveryDetailsAction = createAsyncThunk(
    'delivery/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/users/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const DeliveryVerifyAction = createAsyncThunk(
    'shop/verify', async (reqBody, thunkAPI) => {
        try {
            const { id, ...payload } = reqBody;
            const response = await Api.put(`/delivery-partners/${id}/verify`, payload)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


const DeliverySuspendAction = createAsyncThunk(
    'delivery/suspend', async (reqBody, thunkAPI) => {
        try {
            const { id, ...payload } = reqBody;
            const response = await Api.patch(`/users/manage/${id}`, payload)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


const getDeliveryChargesAction = createAsyncThunk(
    'delivery/get', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/delivery-charges`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const addDeliveryChargesAction = createAsyncThunk(
    'delivery/add', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post(`/delivery-charges`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const editDeliveryChargesAction = createAsyncThunk(
    'delivery/edit', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`/delivery-charges`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const deleteDeliveryAction = createAsyncThunk(
    'delivery/delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`/users/${reqBody}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const DeliveryOrderListAction = createAsyncThunk(
    'delivery-order/list', async (reqBody, thunkAPI) => {
        try {
            const { id, ...payload } = reqBody
            const queryString = constructQueryString(payload);
            const response = await Api.get(`/delivery-partner/${id}/orders?${queryString}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const DeliveryOrderDetailsAction = createAsyncThunk(
    'delivery-order/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/order/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



export {
    DeliveryListAction,
    DeliveryDetailsAction,
    DeliveryVerifyAction,
    DeliverySuspendAction,
    getDeliveryChargesAction,
    addDeliveryChargesAction,
    editDeliveryChargesAction,
    deleteDeliveryAction,
    DeliveryOrderListAction,
    DeliveryOrderDetailsAction
};